<template>
  <div
    style="
      position: relative;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div
      style="
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('/background-login.jpeg.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        filter: blur(5px);
        z-index: 1;
      "
    ></div>

    <div
      style="
        position: relative;
        background: rgba(0, 0, 0, 0.7);
        padding: 40px 30px;
        border-radius: 15px;
        width: 100%;
        max-width: 400px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        text-align: center;
        z-index: 2;
      "
    >
      <img
        src="https://travelfybrasil.com/wp-content/uploads/2023/02/logo-tf.png"
        alt="Logo"
        style="margin-bottom: 20px; max-width: 150px;"
      />
      <a-form
        layout="vertical"
        :model="formState"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item
          :rules="{
            required: true,
            type: 'email',
            message: 'E-mail inválido!',
            trigger: 'change',
          }"
        >
          <a-input
            v-model:value="formState.user"
            placeholder="Digite o seu email :)"
            size="large"
            style="border-radius: 8px;"
          />
        </a-form-item>
        <a-form-item>
          <a-input-password
            v-model:value="formState.password"
            placeholder="Digite sua senha :)"
            size="large"
            style="border-radius: 8px;"
          />
        </a-form-item>
        <a-form-item>
          <a-checkbox style="color: white;" v-model:value="formState.remember">Lembre de mim</a-checkbox>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            block
            size="large"
            html-type="submit"
            :loading="isLoading"
            style="background: #ff0080; border-color: #ff0080; font-weight: bold; font-size: 16px;"
          >
            ACESSAR
          </a-button>
        </a-form-item>
      </a-form>
      <div style="margin-top: 20px;">
        <a href="/forgot-password" style="color: #ffffff; text-decoration: underline;">
          Perdeu sua senha?
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import { message } from "ant-design-vue";
import { defineComponent, reactive } from "vue";
import { mapActions, mapState } from "vuex";
import { isDesktop, isMobile } from "@/utils/isAgent";
export default defineComponent({
  setup() {
    const formState = reactive({
      user: "",
      password: "",
    });

    return {
      formState,
      isDesktop,
      isMobile,
    };
  },
  computed: {
    ...mapState("user", ["isLoading"]),
  },
  methods: {
    ...mapActions("user", ["login"]),

    async handleFinish() {
      try {
        const data = {
          email: this.formState.user,
          password: this.formState.password,
        };
        if (await this.login(data)) {
          this.$router.push("/applications");
        }
      } catch (e) {
        message.error("Não foi possivel fazer login.");
      }
    },

    handleFinishFailed(errors) {
      console.log(errors);
    },
  },
});
</script>

